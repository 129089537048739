import type { Theme, ThemeUICSSObject } from 'theme-ui';

import { breakpointIndexes } from './breakpoints';
import { derivedStyle } from './utils/derivedStyle';

export type FormSize = 'default' | 'large';

/**
 * In case we need to apply similar styles to a third-party component
 */
export function getInputVerticalPadding(size: FormSize): number {
	return size === 'default' ? 7 : 13;
}

/**
 * In case we need to apply similar styles to a third-party component
 */
export function getInputBorderWidth(): number {
	return 1;
}

/**
 * In case we need to apply similar styles to a third-party component
 */
export function getInputFontSize(): number {
	return 16;
}

/**
 * In case we need to apply similar styles to a third-party component
 */
export function getInputLineHeight(): number {
	return 24;
}

/**
 * In case we need to apply similar styles to a third-party component
 */
export function getInputHeight(size: FormSize): number {
	return getInputVerticalPadding(size) * 2 + getInputBorderWidth() * 2 + getInputLineHeight();
}

// Reset autofill styles set by theme-ui
// https://github.com/system-ui/theme-ui/blob/10b33dee2f188e440600e02a4f2bf32d402588d0/packages/components/src/Input.tsx#L28
const autofillStyles: ThemeUICSSObject = {
	boxShadow: 'none',
	fontSize: 14,
	// eslint-disable-next-line @typescript-eslint/naming-convention
	':first-line': {
		fontSize: 'inherit',
	},
};
const baseInputStyles: ThemeUICSSObject = {
	color: 'typography-primary',
	background: 'primary-white',
	borderWidth: getInputBorderWidth(),
	borderRadius: 'standard',
	borderColor: 'neutral-grey600',
	fontFamily: 'body',

	px: 12,
	fontSize: getInputFontSize(),
	lineHeight: `${getInputLineHeight()}px`,
	// eslint-disable-next-line @typescript-eslint/naming-convention
	'&': breakpointIndexes.map((idx) => ({
		[`&[data-size-${idx}=default]`]: {
			py: getInputVerticalPadding('default'),
		},
		[`&[data-size-${idx}=large]`]: {
			py: getInputVerticalPadding('large'),
		},
	})),

	// eslint-disable-next-line @typescript-eslint/naming-convention
	'&[data-error=true]': {
		borderColor: 'primary-changeRed',
		boxShadow: derivedStyle(({ colors }) => `0px 0px 0px 1px ${colors['primary-changeRed']}`),
	},

	// eslint-disable-next-line @typescript-eslint/naming-convention
	'&:focus': {
		borderColor: 'primary-black',
		outline: 'none !important',
		boxShadow: derivedStyle(({ colors }) => `0px 0px 0px 1px ${colors['primary-black']}`),
	},

	// eslint-disable-next-line @typescript-eslint/naming-convention
	':autofill, :autofill:hover, :autofill:focus': autofillStyles,
	// eslint-disable-next-line @typescript-eslint/naming-convention
	':-webkit-autofill, :-webkit-autofill:hover, :-webkit-autofill:focus': autofillStyles,
};

export const forms: Theme['forms'] = {
	input: baseInputStyles,
	select: baseInputStyles,
	textarea: {
		...baseInputStyles,
		resize: 'none',
	},
};
