import type { ComponentPropsWithoutRef, ForwardedRef } from 'react';

import { Divider } from 'theme-ui';

import { forwardRef } from '@change-corgi/core/react/core';

type Props = ComponentPropsWithoutRef<typeof Divider> & {
	label?: string;
};

function SeparatorInner({ label, sx, ...rest }: Props, ref: ForwardedRef<HTMLHRElement>) {
	return (
		<Divider
			data-label={label || undefined}
			sx={{
				textAlign: 'center',
				position: 'relative',
				lineHeight: '18px',
				// eslint-disable-next-line @typescript-eslint/naming-convention
				'&:after': label
					? {
							content: 'attr(data-label)',
							px: 8,
							color: 'neutral-grey400',
							background: 'primary-white',
							textTransform: 'uppercase',
							fontFamily: 'body',
							fontWeight: 'bold',
							fontSize: 12,
							position: 'relative',
							top: -10,
						}
					: {},
				// eslint-disable-next-line @typescript-eslint/no-misused-spread
				...sx, // only required for storybook to take overrides into account
			}}
			{...rest}
			ref={ref}
		/>
	);
}

/**
 * @doc $DOC:Separator
 */
export const Separator = forwardRef(SeparatorInner);
