import type { ComponentPropsWithRef, ForwardedRef, JSX } from 'react';

import { Image as ImageBase } from 'theme-ui';

import { forwardRef } from '@change-corgi/core/react/core';
import type {
	MandatoryResponsiveArrayValue,
	MandatoryResponsiveValue,
	ResponsiveValue,
} from '@change-corgi/design-system/theme';
import { normalizeResponsiveValue, useBreakpoints } from '@change-corgi/design-system/theme';

type ImageProps = Omit<ComponentPropsWithRef<typeof ImageBase>, 'variant' | 'src'> & {
	src?: MandatoryResponsiveValue<string>;
	aspectRatio?: ResponsiveValue<string>;
	fit?: ResponsiveValue<'contain' | 'cover' | 'fill' | 'none' | 'scale-down'>;
	// TODO: might be part of React 18, and therefore could potentially be removed after bump
	fetchPriority?: 'high' | 'low' | 'auto';
};

function isSrcArray(src: MandatoryResponsiveValue<string>): src is MandatoryResponsiveArrayValue<string> {
	return Array.isArray(src);
}

type SrcWithMaxWidth = {
	src: string;
	maxWidth: string | undefined;
};

function ImageInner(
	{ children, src, aspectRatio, fit: objectFit, fetchPriority: fetchpriority, sx, ...rest }: ImageProps,
	ref: ForwardedRef<HTMLImageElement>,
): JSX.Element {
	const breakpoints = useBreakpoints();

	if (!src || !isSrcArray(src) || src.length < 2) {
		return (
			<ImageBase
				sx={{
					aspectRatio,
					objectFit,
					// eslint-disable-next-line @typescript-eslint/no-misused-spread
					...sx, // only required for storybook to take overrides into account
				}}
				// all this so we can have proper camel case without having React complaining...
				{...(fetchpriority ? { fetchpriority } : {})}
				{...rest}
				src={src && isSrcArray(src) ? src[0] : src}
				ref={ref}
			>
				{children}
			</ImageBase>
		);
	}

	const srcWithMaxWidthArray = normalizeResponsiveValue(src)
		.map(
			(srcItem, idx) =>
				({
					src: srcItem,
					maxWidth: breakpoints[idx],
				}) as SrcWithMaxWidth,
		)
		.reduce<SrcWithMaxWidth[]>((acc, srcWithMaxWidth) => {
			if (acc.length && srcWithMaxWidth.src === acc[acc.length - 1].src) {
				// eslint-disable-next-line no-param-reassign
				acc[acc.length - 1] = srcWithMaxWidth;
			} else {
				acc.push(srcWithMaxWidth);
			}
			return acc;
		}, []);
	const lastItem = srcWithMaxWidthArray.pop() as SrcWithMaxWidth;
	return (
		<picture>
			{srcWithMaxWidthArray.map(({ src: srcSet, maxWidth }, idx) => (
				// eslint-disable-next-line react/no-array-index-key, @typescript-eslint/no-non-null-assertion
				<source key={idx} srcSet={srcSet} media={`(max-width: ${maxWidth!})`} />
			))}
			<ImageBase
				sx={{
					aspectRatio,
					objectFit,
					// eslint-disable-next-line @typescript-eslint/no-misused-spread
					...sx, // only required for storybook to take overrides into account
				}}
				// all this so we can have proper camel case without having React complaining...
				{...(fetchpriority ? { fetchpriority } : {})}
				{...rest}
				src={lastItem.src}
				ref={ref}
			>
				{children}
			</ImageBase>
		</picture>
	);
}

/**
 * @doc $DOC:Image
 */
export const Image = forwardRef(ImageInner);
