/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-lines-per-function */
import type { ThemeUICSSObject } from 'theme-ui';

import { breakpointIndexes } from './breakpoints';

// https://theme-ui.com/components/variants
export type ButtonVariant = 'primary' | 'secondary' | 'secondaryEmphasis';

export type ButtonSize = 'small' | 'medium' | 'large';

const PADDINGS: Record<ButtonSize, { x: number; y: number }> = {
	small: { x: 16, y: 8 },
	medium: { x: 24, y: 10 },
	large: { x: 28, y: 12 },
};

const PADDINGS_ICON_ONLY: Record<ButtonSize, number> = {
	small: 8,
	medium: 10,
	large: 12,
};

function genBorderStyles(width: number) {
	return {
		borderWidth: `${width}px`,
		// "[data-size-0]" selector is use to avoid overriding "&" selector in commonButtonStyles
		'&[data-size-0]': breakpointIndexes.map((idx) => ({
			// fixes a priority issue in generated styles
			[idx > 0 ? `&[data-size-${idx}]` : '&']: {
				[`&[data-size-${idx}=small]`]: {
					[`&:not([data-mode-${idx}=icon])`]: {
						padding: `${PADDINGS.small.y - width}px ${PADDINGS.small.x - width}px`,
					},
					[`&[data-mode-${idx}=icon]`]: {
						padding: `${PADDINGS_ICON_ONLY.small - width}px`,
					},
				},
				[`&[data-size-${idx}=medium]`]: {
					[`&:not([data-mode-${idx}=icon])`]: {
						padding: `${PADDINGS.medium.y - width}px ${PADDINGS.medium.x - width}px`,
					},
					[`&[data-mode-${idx}=icon]`]: {
						padding: `${PADDINGS_ICON_ONLY.medium - width}px`,
					},
				},
				[`&[data-size-${idx}=large]`]: {
					[`&:not([data-mode-${idx}=icon])`]: {
						padding: `${PADDINGS.large.y - width}px ${PADDINGS.large.x - width}px`,
					},
					[`&[data-mode-${idx}=icon]`]: {
						padding: `${PADDINGS_ICON_ONLY.large - width}px`,
					},
				},
			},
		})),
	};
}

const thinBorder = genBorderStyles(1);
const thickBorder = genBorderStyles(2);

const disabled = {
	bg: 'neutral-grey100',
	borderColor: 'neutral-grey600',
	...thinBorder,
	cursor: 'not-allowed',
	'[data-label]': {
		color: 'typography-secondary',
	},
	'[data-icon]': {
		color: 'neutral-grey700',
	},
};

// using this approach for a11y for wider screen reader support
// https://www.sarasoueidan.com/blog/accessible-icon-buttons/ (technique #1)
const visuallyVisible = {
	clip: 'initial',
	clipPath: 'initial',
	height: 'auto',
	width: 'auto',
	overflow: 'initial',
	position: 'initial',
	whiteSpace: 'inherit',
};
const visuallyHidden = {
	clip: 'rect(0 0 0 0)',
	clipPath: 'inset(50%)',
	height: '1px',
	width: '1px',
	overflow: 'hidden',
	position: 'absolute',
	whiteSpace: 'nowrap',
	// necessary for this to work within a container with overflow
	top: 0,
	left: 0,
};

const commonButtonStyles: ThemeUICSSObject = {
	borderStyle: 'solid',
	cursor: 'pointer',
	fontFamily: 'body',
	fontWeight: 'bold',
	/*
	 * this generates a list of selector that target a specific data-size-x for a specific media query
	 *
	 * for instance:
	 * @media screen and (min-width: 768px) .css-1udvbqg[data-size-1=small] {
	 *     font-size: 14px;
	 *     line-height: 20px;
	 *     min-height: 36px;
	 * }
	 * @media screen and (min-width: 1024px) .css-1udvbqg[data-size-2=medium] {
	 *     font-size: 16px;
	 *     line-height: 24px;
	 *     min-height: 44px;
	 * }
	 */
	'&': breakpointIndexes.map((idx) => ({
		[`&[data-mode-${idx}=label]`]: {
			borderRadius: 'standard',
			'[data-icon]': {
				display: 'none',
				'&[data-icon-position=start]': {
					marginRight: 0,
					marginLeft: 0,
				},
				'&[data-icon-position=end]': {
					marginRight: 0,
					marginLeft: 0,
				},
			},
			'[data-label]': visuallyVisible,
		},
		[`&[data-mode-${idx}=icon]`]: {
			borderRadius: 'standard',
			'[data-icon]': {
				display: 'block',
				'&[data-icon-position=start]': {
					marginRight: 0,
					marginLeft: 0,
				},
				'&[data-icon-position=end]': {
					marginRight: 0,
					marginLeft: 0,
				},
			},
			'[data-label]': visuallyHidden,
		},
		[`&[data-mode-${idx}="icon+label"]`]: {
			borderRadius: 'standard',
			'[data-icon]': {
				display: 'block',
				'&[data-icon-position=start]': {
					marginRight: 8,
					marginLeft: 0,
				},
				'&[data-icon-position=end]': {
					marginRight: 0,
					marginLeft: 8,
				},
			},
			'[data-label]': visuallyVisible,
		},
		[`&[data-size-${idx}=small]`]: {
			fontSize: 14,
			lineHeight: '20px',
			minHeight: '36px',
			[`&:not([data-mode-${idx}=icon])`]: {
				minWidth: 'auto',
			},
			[`&[data-mode-${idx}=icon]`]: {
				minWidth: '36px',
			},
			'[data-icon]': {
				fontSize: 16,
			},
		},
		[`&[data-size-${idx}=medium]`]: {
			fontSize: 16,
			lineHeight: '24px',
			minHeight: '44px',
			[`&:not([data-mode-${idx}=icon])`]: {
				minWidth: 'auto',
			},
			[`&[data-mode-${idx}=icon]`]: {
				minWidth: '44px',
			},
			'[data-icon]': {
				fontSize: 20,
			},
		},
		[`&[data-size-${idx}=large]`]: {
			fontSize: 20,
			lineHeight: '28px',
			minHeight: '52px',
			[`&:not([data-mode-${idx}=icon])`]: {
				minWidth: 'auto',
			},
			[`&[data-mode-${idx}=icon]`]: {
				minWidth: '52px',
			},
			'[data-icon]': {
				fontSize: 24,
			},
		},
	})),
	...thickBorder,
	borderColor: 'transparent',
	'&[data-loading=true]': {
		position: 'relative',
		'[data-label],[data-icon]': {
			visibility: 'hidden',
		},
	},
};

export const buttons: Record<ButtonVariant, ThemeUICSSObject> = {
	primary: {
		...commonButtonStyles,
		// using color as a basis for the background and border colors
		// for more flexibility
		color: 'primary-changeRed',
		bg: 'currentColor',
		borderColor: 'currentColor',
		'& > *': {
			color: 'primary-white',
		},
		'&:active, &:hover': {
			// darkens the element (and therefore the color)
			// the drawback is that the text and border are also darkened
			filter: 'brightness(0.8)',
			borderColor: 'primary-black',
		},
		'&:focus': {
			borderColor: 'primary-black',
		},
		// [disabled] for non-button elements (e.g. button links)
		'&:disabled, &:disabled:hover, &[disabled], &[disabled]:hover': {
			filter: 'none',
			...disabled,
		},
	},
	secondary: {
		...commonButtonStyles,
		bg: 'primary-white',
		...thinBorder,
		borderColor: 'neutral-grey600',
		color: 'primary-black',
		'&:active, &:hover, &:focus': {
			borderColor: 'primary-black',
			...thickBorder,
		},
		'[data-label]': {
			color: 'primary-black',
		},
		'[data-icon]': {
			color: 'currentColor',
		},
		// [disabled] for non-button elements (e.g. button links)
		'&:disabled, &:disabled:hover, &[disabled], &[disabled]:hover': {
			...disabled,
		},
	},
	secondaryEmphasis: {
		...commonButtonStyles,
		bg: 'primary-white',
		...thinBorder,
		color: 'primary-changeRed',
		borderColor: 'currentColor',
		'&:active, &:hover, &:focus': {
			...thickBorder,
		},
		'[data-label]': {
			color: 'currentColor',
		},
		'[data-icon]': {
			color: 'currentColor',
		},
		// [disabled] for non-button elements (e.g. button links)
		'&:disabled, &:disabled:hover, &[disabled], &[disabled]:hover': {
			...disabled,
		},
	},
};
