import type { ComponentPropsWithRef, ForwardedRef, JSX, PropsWithChildren } from 'react';
import { Children, isValidElement, useCallback, useMemo, useRef, useState } from 'react';

import { forwardRef } from '@change-corgi/core/react/core';
import { useI18n } from '@change-corgi/core/react/i18n';
import { Box, Flex } from '@change-corgi/design-system/layout';

import { Dots } from './components/Dots';
import { NavigationButtons } from './components/NavigationButtons';
import { useIntersectionObserver } from './hooks/useIntersectionObserver';
import { useScrollEnd } from './hooks/useScrollEnd';

const BUTTON_SIZE = 36;
const CAROUSEL_PADDING = 24;

type Props = PropsWithChildren<ComponentPropsWithRef<typeof Box>> & { ariaLabel?: string };
// eslint-disable-next-line max-lines-per-function
function CarouselInner(
	{ children, ariaLabel, sx, ...rest }: Props,
	ref: ForwardedRef<HTMLElement>,
): JSX.Element | null {
	const carouselRef = useRef<HTMLElement | null>(null);
	const currentSlideRef = useRef<HTMLElement | null>(null);
	const [activeIndex, setActiveIndex] = useState<number>(0);

	const { translate } = useI18n();

	const totalItems = useMemo(() => Children.count(children), [children]);
	const hasMultipleSlides = totalItems > 1;
	const disabledPrevBtn = activeIndex === 0;
	const disabledNextBtn = activeIndex + 1 === totalItems;

	const prevSlide = () => {
		if (!carouselRef.current || activeIndex === 0) return;

		const currentSlide = currentSlideRef.current ?? carouselRef.current.children[totalItems - 1];

		const prevElement = currentSlide.previousElementSibling;
		if (!prevElement || !(prevElement instanceof HTMLDivElement)) return;

		const delta = Math.abs(carouselRef.current.offsetLeft - prevElement.offsetLeft);
		carouselRef.current.scrollTo(delta, 0);
		currentSlideRef.current = prevElement;
	};

	const nextSlide = () => {
		if (!carouselRef.current || activeIndex === Children.count(children) - 1) return;

		const currentSlide = currentSlideRef.current ?? carouselRef.current.children[0];

		const nextElement = currentSlide.nextElementSibling;
		if (!nextElement || !(nextElement instanceof HTMLDivElement)) return;

		const delta = Math.abs(carouselRef.current.offsetLeft - nextElement.offsetLeft);
		carouselRef.current.scrollTo(delta, 0);
		currentSlideRef.current = nextElement;
	};

	// setup observer: The current intersected entry is returned from this hook
	// We track when an element has been intersected so we can perform side-effects after the user has completed scrolling.
	const entry = useIntersectionObserver({ root: carouselRef, threshold: 0.5 });

	// synchronize relevant state on scroll end
	const handleScrollEnd = useCallback(() => {
		if (carouselRef.current && entry && entry.target instanceof HTMLElement) {
			const index = Array.from(carouselRef.current.childNodes).indexOf(entry.target);
			setActiveIndex(index);
			currentSlideRef.current = entry.target;
			// TODO: should we refocus the button if at the last element?
		}
	}, [entry]);
	// this hook wraps manually sets up the `scrollend` event since this is not supported
	// in react or all browsers at time of writing this
	useScrollEnd(carouselRef, handleScrollEnd);

	if (totalItems === 0) return null;

	// if only one slide, revert from carousel to div to exclude carousel a11y
	if (!hasMultipleSlides)
		return (
			<Box {...rest}>
				<Box id="carousel-items">
					<Box id="carousel-item-1" backgroundColor="background" sx={{ borderRadius: 'standard' }}>
						{children}
					</Box>
				</Box>
			</Box>
		);

	return (
		<Box
			as="section"
			aria-roledescription="carousel"
			aria-label={ariaLabel || translate('design-system.carousel.label')}
			ref={ref}
			sx={{
				display: 'grid',
				gridTemplateColumns: `
				[carousel-gutter] calc(${BUTTON_SIZE}px + (${CAROUSEL_PADDING}px * 2))
				[carousel-scroller] 1fr
				[carousel-gutter] calc(${BUTTON_SIZE}px + (${CAROUSEL_PADDING}px * 2))`,
				gridTemplateRows: `
				[carousel-scroller] 1fr
				[carousel-navigation] max-content`,
				position: 'relative',
				overflow: 'hidden',
				// eslint-disable-next-line @typescript-eslint/no-misused-spread
				...sx, // only required for storybook to take overrides into account
			}}
			{...rest}
		>
			<NavigationButtons
				previous={{ onClick: prevSlide, disabled: disabledPrevBtn }}
				next={{ onClick: nextSlide, disabled: disabledNextBtn }}
			/>
			<Flex
				id="carousel-items"
				aria-live="polite"
				ref={carouselRef}
				/* eslint-disable @typescript-eslint/naming-convention */
				sx={{
					display: 'grid',
					gridRow: 1,
					gridColumn: '1/-1',
					gridAutoColumns: [`calc(100% - ${BUTTON_SIZE}px)`, '100%'],
					gridAutoFlow: 'column',
					gap: 16,

					overflowX: 'auto',
					overscrollBehaviorX: 'contain',
					scrollSnapType: 'x mandatory',
					scrollPaddingInline: 16,
					/* Hide scrollbar for IE, Edge and Firefox */
					msOverflowStyle: 'none' /* IE and Edge */,
					scrollbarWidth: 'none' /* Firefox */,
					'--webkit-overflow-scrolling': 'touch',
					/* Hide scrollbar for Chrome, Safari and Opera */
					'&::-webkit-scrollbar': {
						display: 'none',
					},
					'&::-webkit-scrollbar-thumb': {
						backgroundColor: 'transparent',
					},
					'@media (prefers-reduced-motion: no-preference)': {
						scrollBehavior: 'smooth',
					},
				}}
				/* eslint-enable @typescript-eslint/naming-convention */
			>
				{Children.map(children, (child, index) => {
					if (!isValidElement(child)) return null;

					return (
						<Box
							id={`carousel-item-${index + 1}`}
							as="div" // DO NOT CHANGE: as `div` is required for the event handlers to work
							role="group"
							aria-roledescription="slide"
							aria-label={translate('design-system.carousel.slide', { slideNumber: index + 1, totalItems })}
							// eslint-disable-next-line react/no-array-index-key
							key={`slide-${index}-of-${totalItems}`}
							sx={{
								backgroundColor: 'background',
								borderRadius: 'standard',
								scrollSnapAlign: 'center',
								scrollSnapStop: 'always',
							}}
							// EDIT ME (match gutter above) TO CHANGE WIDTH OF SLIDE CONTENT
							px={[0, `calc(${BUTTON_SIZE}px + ${CAROUSEL_PADDING}px)`]}
							aria-hidden={index !== activeIndex}
						>
							{child}
						</Box>
					);
				})}
			</Flex>

			<Dots totalItems={totalItems} activeIndex={activeIndex} />
		</Box>
	);
}

/**
 * @doc $DOC:Carousel
 */
export const Carousel = forwardRef(CarouselInner);
