import type { JSX, PropsWithChildren, ReactNode } from 'react';

import { VisuallyHidden } from '@change-corgi/design-system/a11y';
import { Box, Flex } from '@change-corgi/design-system/layout';
import type { FormSize, ResponsiveValue } from '@change-corgi/design-system/theme';
import { normalizeResponsiveValue } from '@change-corgi/design-system/theme';
import type { TextSize } from '@change-corgi/design-system/typography';
import { Text } from '@change-corgi/design-system/typography';

import { FormError } from './FormError';

type Props = {
	caption?: string;
	error?: ReactNode;
	errorId?: string;
	helperText?: string;
	helperTextId?: string;
	label?: string;
	labelId?: string;
	hideLabel?: ResponsiveValue<boolean>;
	size?: ResponsiveValue<FormSize>;
};

const TEXT_SIZE_MAP: Record<FormSize, TextSize> = {
	default: 'caption',
	large: 'small',
};
const ERROR_TEXT_SIZE_MAP: Record<FormSize, TextSize> = {
	default: 'small',
	large: 'default',
};

function getTextSize(size: ResponsiveValue<FormSize> | undefined) {
	return {
		textSizeResponsive: normalizeResponsiveValue(size || 'default').map(
			// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
			(s) => TEXT_SIZE_MAP[s as FormSize] || s,
		) as ResponsiveValue<TextSize>,
		errorTextSizeResponsive: normalizeResponsiveValue(size || 'default').map(
			// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
			(s) => ERROR_TEXT_SIZE_MAP[s as FormSize] || s,
		) as ResponsiveValue<TextSize>,
	};
}

function InputWrapperLabel({
	caption,
	label,
	hideLabel,
	size,
}: Pick<Props, 'caption' | 'label' | 'hideLabel' | 'size'>) {
	const { textSizeResponsive } = getTextSize(size);
	return (
		<>
			{hideLabel !== true && (
				<Flex
					mb={4}
					sx={{
						justifyContent: 'space-between',
						display: Array.isArray(hideLabel) ? hideLabel.map((hidden) => (hidden ? 'none' : 'flex')) : 'flex',
					}}
				>
					{label && (
						<Text size={textSizeResponsive} sx={{ fontWeight: 'bold' }}>
							{label}
						</Text>
					)}
					{caption && (
						<Text size={textSizeResponsive} color="typography-secondary">
							{caption}
						</Text>
					)}
				</Flex>
			)}
			{hideLabel && (
				<VisuallyHidden
					sx={{
						justifyContent: 'space-between',
						display: Array.isArray(hideLabel) ? hideLabel.map((hidden) => (hidden ? 'flex' : 'none')) : 'flex',
					}}
				>
					{label && (
						<Text size={textSizeResponsive} sx={{ fontWeight: 'bold' }}>
							{label}
						</Text>
					)}
					{caption && (
						<Text size={textSizeResponsive} color="typography-secondary">
							{caption}
						</Text>
					)}
				</VisuallyHidden>
			)}
		</>
	);
}

export function InputWrapper({
	caption,
	children,
	error,
	errorId,
	helperText,
	helperTextId,
	label,
	labelId,
	hideLabel,
	size,
}: PropsWithChildren<Props>): JSX.Element {
	const { textSizeResponsive, errorTextSizeResponsive } = getTextSize(size);
	return (
		<>
			<Box as="label" id={labelId} sx={{ color: 'typography-primary' }}>
				{(label || caption) && <InputWrapperLabel label={label} caption={caption} hideLabel={hideLabel} size={size} />}

				{helperText && (
					<Text as="div" id={helperTextId} size={textSizeResponsive} mb={4} color="typography-secondary">
						{helperText}
					</Text>
				)}

				{children}
			</Box>

			{error ? (
				<FormError id={errorId} size={errorTextSizeResponsive} pt={4}>
					{error}
				</FormError>
			) : null}
		</>
	);
}
