import { useCallback } from 'react';
import type { JSX } from 'react';

import type { ThemeUICSSObject } from 'theme-ui';

import { Translate, TranslatePlural, useI18n } from '@change-corgi/core/react/i18n';
import { Link } from '@change-corgi/design-system/components/actions';
import { iconArrowUpward, iconChatBubble, iconTrendingUp, iconVictory } from '@change-corgi/design-system/icons';

import { Badge } from '../../Badge';

type BadgeType = 'promoted' | 'victory' | 'new-supporters' | 'has-dm-response';

type Props = {
	badgeType: BadgeType;
	dmName?: string;
	promoterCount?: number;
	promoterCountLocalized?: string;
	promotersUrl?: string;
	supporterCount?: number;
	supporterCountLocalized?: string;
};

export type { Props as CardBadgeProps };

const sx: ThemeUICSSObject = { position: 'absolute', bottom: 0, width: '100%' };

export function CardBadge({
	badgeType,
	dmName,
	promoterCount,
	promoterCountLocalized,
	promotersUrl,
	supporterCount,
	supporterCountLocalized,
}: Props): JSX.Element | null {
	const { localizeNumber } = useI18n();

	const getLocalizedCount = useCallback(
		(count: number | undefined, countLocalized: string | undefined) => countLocalized ?? localizeNumber(count || 0),
		[localizeNumber],
	);

	switch (badgeType) {
		case 'new-supporters':
			return (
				<Badge
					sx={sx}
					variant="neutral"
					icon={iconTrendingUp}
					data-qa="petition-card-badge"
					data-badge-type={badgeType}
				>
					<TranslatePlural
						value="design-system.petition-card.badge.new-supporters"
						count={supporterCount || 0}
						replacements={{
							supporterCount: getLocalizedCount(supporterCount, supporterCountLocalized),
						}}
					/>
				</Badge>
			);

		case 'promoted':
			return (
				<Badge
					sx={sx}
					variant="neutral"
					icon={iconArrowUpward}
					data-qa="petition-card-badge"
					data-badge-type={badgeType}
				>
					{promotersUrl ? (
						<Link to={promotersUrl}>
							<TranslatePlural
								value="design-system.petition-card.badge.promoted"
								count={promoterCount || 0}
								replacements={{ promoterCount: getLocalizedCount(promoterCount, promoterCountLocalized) }}
							/>
						</Link>
					) : (
						<TranslatePlural
							value="design-system.petition-card.badge.promoted"
							count={promoterCount || 0}
							replacements={{ promoterCount: getLocalizedCount(promoterCount, promoterCountLocalized) }}
						/>
					)}
				</Badge>
			);

		case 'victory':
			return (
				<Badge sx={sx} variant="neutral" icon={iconVictory} data-qa="petition-card-badge" data-badge-type={badgeType}>
					<Translate value="design-system.petition-card.badge.victory" />
				</Badge>
			);

		case 'has-dm-response':
			return (
				<Badge
					sx={sx}
					variant="neutral"
					icon={iconChatBubble}
					data-qa="petition-card-badge"
					data-badge-type={badgeType}
				>
					<Translate
						value="design-system.petition-card.badge.has-dm-response"
						replacements={{ dmName: dmName || '' }}
					/>
				</Badge>
			);

		default:
			return null;
	}
}
