import type { ThemeUICSSObject } from 'theme-ui';

import type { BreakpointsValue, ResponsiveValue } from '@change-corgi/design-system/theme';
import { normalizeResponsiveValue } from '@change-corgi/design-system/theme';

type Options = {
	ellipsis?: boolean;
	lineClamp?: ResponsiveValue<number>;
	breakWord?: boolean;
};

const breakWordStyles: ThemeUICSSObject = {
	wordBreak: 'break-word',
	wordWrap: 'break-word',
};

const ellipsisStyles: ThemeUICSSObject = {
	display: 'block',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
};

function lineClampStyles(lineClamp: BreakpointsValue<number | null | undefined>): ThemeUICSSObject {
	return {
		overflow: 'hidden',
		display: '-webkit-box',
		wordWrap: 'break-word',
		WebkitLineClamp: lineClamp.map((v) => (v ? `${v}` : v)), // needs to be casted into string otherwise it's transformed into px
		WebkitBoxOrient: 'vertical',
	};
}

export function getTextStyles({ ellipsis, lineClamp, breakWord }: Options): ThemeUICSSObject {
	const lineClampResponsive = lineClamp && normalizeResponsiveValue(lineClamp);
	return {
		...(ellipsis && ellipsisStyles),
		...(breakWord && breakWordStyles),
		...(lineClampResponsive && lineClampStyles(lineClampResponsive)),
	};
}
