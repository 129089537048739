import type { ComponentPropsWithRef, ForwardedRef, JSX, PropsWithoutRef } from 'react';

import { Flex } from 'theme-ui';

import { forwardRef } from '@change-corgi/core/react/core';
import { Link } from '@change-corgi/core/react/router';
import { Icon } from '@change-corgi/design-system/components/icon';
import type { IconComponent } from '@change-corgi/design-system/icons';
import type { PropsWithoutAs } from '@change-corgi/design-system/types';
import { Text } from '@change-corgi/design-system/typography';

import { baseStyles } from './shared/baseStyles';

type ChipProps = ComponentPropsWithRef<typeof Flex> & {
	icon?: IconComponent;
	selected?: boolean;
};

export type Props = Omit<
	PropsWithoutAs<typeof Link, PropsWithoutRef<ChipProps>>,
	keyof React.ComponentPropsWithoutRef<'div'>
> &
	React.ComponentPropsWithoutRef<'a'> & {
		onClick?: React.ComponentPropsWithoutRef<typeof Link>['onClick'];
	};

function ChipLinkInner(
	{ children, icon, selected, sx, ...rest }: Props,
	ref: ForwardedRef<HTMLImageElement>,
): JSX.Element {
	return (
		<Flex
			as={Link}
			ref={ref as unknown}
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			{...(rest as any)}
			sx={{
				color: 'typography-primary',
				...baseStyles(selected, sx, true),
				textDecoration: 'none',
				// eslint-disable-next-line @typescript-eslint/no-misused-spread
				...sx, // only required for storybook to take overrides into account
			}}
		>
			{icon && <Icon size="m" icon={icon} mr={8} />}
			<Text size="default" ellipsis>
				{children}
			</Text>
		</Flex>
	);
}

/**
 * @doc $DOC:ChipLink
 */
export const ChipLink = forwardRef(ChipLinkInner);
