import type { ComponentPropsWithRef, ForwardedRef, JSX, ReactNode } from 'react';

import styled from '@emotion/styled';
import { Alert } from 'theme-ui';

import { forwardRef } from '@change-corgi/core/react/core';
import { Translate } from '@change-corgi/core/react/i18n';
import { VisuallyHidden } from '@change-corgi/design-system/a11y';
import { Icon } from '@change-corgi/design-system/components/icon';
import type { IconComponent } from '@change-corgi/design-system/icons';
import { iconCheckCircle, iconClose, iconError, iconInfo, iconWarning } from '@change-corgi/design-system/icons';
import { Box, Flex } from '@change-corgi/design-system/layout';
import { Text } from '@change-corgi/design-system/typography';

export type Variant = 'critical' | 'info' | 'success' | 'warning';
export type TitleSize = 'default' | 'large';

type Props = Omit<ComponentPropsWithRef<typeof Alert>, 'variant' | 'title'> & {
	links?: JSX.Element;
	onClose?: React.MouseEventHandler<HTMLButtonElement>;
	title: ReactNode | ReactNode[];
	titleSize?: 'small' | 'default';
	variant: Variant;
};

export const variants: Variant[] = ['critical', 'info', 'success', 'warning'];

const VARIANT_MAP: Record<Variant, { bg: string; icon: IconComponent; iconColor: string }> = {
	critical: {
		bg: 'status-critical500',
		icon: iconError,
		iconColor: 'primary-white',
	},
	info: {
		bg: 'status-info500',
		icon: iconInfo,
		iconColor: 'primary-white',
	},
	success: {
		bg: 'status-positive500',
		icon: iconCheckCircle,
		iconColor: 'typography-lightPrimary',
	},
	warning: {
		bg: 'primary-black',
		icon: iconWarning,
		iconColor: 'status-warning500',
	},
};

const CloseButton = styled.button`
	background-color: transparent;
	border: none;
	color: white;
	cursor: pointer;
	margin: 0;
	padding: 0;
	position: absolute;
	top: 0;
	right: 0;
`;

function SectionMessageInner(
	{ links, children, onClose, title, titleSize, variant, sx, ...rest }: Props,
	ref: ForwardedRef<HTMLDivElement>,
): JSX.Element {
	return (
		<Alert
			p={16}
			sx={{
				bg: VARIANT_MAP[variant].bg,
				borderRadius: 'standard',
				// eslint-disable-next-line @typescript-eslint/no-misused-spread
				...sx, // only required for storybook to take overrides into account
			}}
			ref={ref}
			{...rest}
		>
			<Flex sx={{ position: 'relative', width: '100%' }}>
				<Icon color={VARIANT_MAP[variant].iconColor} icon={VARIANT_MAP[variant].icon} size={20} mr={8} />
				<div>
					<Box>
						<Text size={titleSize === 'small' ? 'default' : 'large'} sx={{ fontWeight: 'bold', lineHeight: '1.3' }}>
							{title}
						</Text>
					</Box>
					{children && (
						<Box mt={8}>
							<Text size="small" sx={{ fontWeight: 'normal' }}>
								{children}
							</Text>
						</Box>
					)}
					{links && <Box mt={8}>{links}</Box>}
				</div>
				{onClose ? (
					<CloseButton onClick={onClose}>
						<Icon icon={iconClose} />
						{/* using this approach for a11y for wider screen reader support
						https://www.sarasoueidan.com/blog/accessible-icon-buttons/ (technique #1) */}
						<VisuallyHidden>
							<Translate value="design-system.section-message.close-label" />
						</VisuallyHidden>
					</CloseButton>
				) : null}
			</Flex>
		</Alert>
	);
}

/**
 * @doc $DOC:SectionMessage
 */
export const SectionMessage = forwardRef(SectionMessageInner);
