import type { ComponentPropsWithRef, ForwardedRef, JSX } from 'react';

import type { Property } from 'csstype';
import type { ThemeUICSSObject } from 'theme-ui';
import { Text as TextBase } from 'theme-ui';

import { forwardRef } from '@change-corgi/core/react/core';
import type { ColorName, FontWeight, ResponsiveValue } from '@change-corgi/design-system/theme';

import { getTextStyles } from './shared/styles';

export type TextSize = 'micro' | 'caption' | 'small' | 'default' | 'large' | 'huge';

type Props = Omit<ComponentPropsWithRef<typeof TextBase>, 'variant' | 'color' | 'backgroundColor' | 'bg'> & {
	ellipsis?: boolean;
	lineClamp?: ResponsiveValue<number>;
	breakWord?: boolean;
	size?: ResponsiveValue<TextSize>;
	color?: ResponsiveValue<ColorName | Property.Color | undefined>;
	fontWeight?: ResponsiveValue<Exclude<FontWeight, 'heading'> | undefined>;
	backgroundColor?: ResponsiveValue<ColorName | Property.BackgroundColor | undefined>;
};

export const SIZE_STYLES: Record<TextSize, { fontSize: number; lineHeight: number }> = {
	micro: {
		fontSize: 10,
		lineHeight: 18,
	},
	caption: {
		fontSize: 12,
		lineHeight: 20,
	},
	small: {
		fontSize: 14,
		lineHeight: 20,
	},
	default: {
		fontSize: 16,
		lineHeight: 24,
	},
	large: {
		fontSize: 18,
		lineHeight: 28,
	},
	huge: {
		fontSize: 24,
		lineHeight: 40,
	},
};

export function getTextLineHeight(size: TextSize): number {
	return SIZE_STYLES[size].lineHeight;
}

export function getTextSizeStyles(size: ResponsiveValue<TextSize> = 'default'): ThemeUICSSObject {
	return {
		fontSize: typeof size === 'string' ? SIZE_STYLES[size].fontSize : size.map((s) => s && SIZE_STYLES[s].fontSize),
		lineHeight:
			typeof size === 'string'
				? `${SIZE_STYLES[size].lineHeight}px`
				: size.map((s) => s && `${SIZE_STYLES[s].lineHeight}px`),
	};
}

function TextInner(
	{ children, ellipsis, lineClamp, breakWord, color, fontWeight, size, sx, ...rest }: Props,
	ref: ForwardedRef<HTMLDivElement>,
): JSX.Element {
	return (
		<TextBase
			variant="default"
			sx={{
				...getTextSizeStyles(size),
				...getTextStyles({ ellipsis, lineClamp, breakWord }),
				fontWeight,
				// eslint-disable-next-line @typescript-eslint/no-misused-spread
				...sx, // only required for storybook to take overrides into account
			}}
			// there is a weird typing issue with color in theme-ui even though it does work with a responsive array
			// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
			color={color as any}
			{...rest}
			ref={ref}
		>
			{children}
		</TextBase>
	);
}

/**
 * @doc $DOC:Text
 */
export const Text = forwardRef(TextInner);
