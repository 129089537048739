import type { ComponentPropsWithRef, ForwardedRef, JSX } from 'react';

import { forwardRef, memo } from '@change-corgi/core/react/core';
import { parseHtml } from '@change-corgi/core/react/html';
import { Box } from '@change-corgi/design-system/layout';
import type { ResponsiveValue } from '@change-corgi/design-system/theme';

type Props = Omit<ComponentPropsWithRef<typeof Box>, 'variant'> & {
	aspectRatio?: ResponsiveValue<string>;
	embedHtml: string;
};

function EmbeddedVideoInner(
	{ embedHtml, aspectRatio, sx, ...rest }: Props,
	ref: ForwardedRef<HTMLDivElement>,
): JSX.Element {
	return (
		<Box
			sx={{
				aspectRatio,
				overflow: 'hidden',
				// eslint-disable-next-line @typescript-eslint/naming-convention
				'& iframe, & embed, & object, & video': {
					height: '100%',
					width: '100%',
				},
				// eslint-disable-next-line @typescript-eslint/no-misused-spread
				...sx, // only required for storybook to take overrides into account
			}}
			{...rest}
			ref={ref}
		>
			{parseHtml(embedHtml, {
				allowedTags: ['iframe'],
				allowedAttrs: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'],
			})}
		</Box>
	);
}

/**
 * @doc $DOC:EmbeddedVideo
 */
export const EmbeddedVideo = memo(forwardRef(EmbeddedVideoInner));
