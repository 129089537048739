import type { ComponentPropsWithRef, ForwardedRef, JSX } from 'react';

import { forwardRef } from '@change-corgi/core/react/core';
import { Icon } from '@change-corgi/design-system/components/icon';
import { iconError } from '@change-corgi/design-system/icons';
import { Flex } from '@change-corgi/design-system/layout';
import type { ResponsiveValue } from '@change-corgi/design-system/theme';
import type { TextSize } from '@change-corgi/design-system/typography';
import { Text } from '@change-corgi/design-system/typography';

type Props = Omit<ComponentPropsWithRef<typeof Flex>, 'variant' | 'color' | 'backgroundColor'> & {
	size?: ResponsiveValue<TextSize>;
};

function FormErrorInner({ children, size, sx, ...rest }: Props, ref: ForwardedRef<HTMLElement>): JSX.Element {
	return (
		<Flex
			sx={{
				alignItems: 'start',
				// eslint-disable-next-line @typescript-eslint/no-misused-spread
				...sx, // only required for storybook to take overrides into account
			}}
			ref={ref}
			{...rest}
		>
			{/* using this trick to align the icon with the first line of the message */}
			<Text as="div" size={size || 'small'} sx={{ display: 'flex', alignItems: 'center' }}>
				{/* invisible character that forces the container to apply the line-height */}
				&#8205;
				<Icon color="primary-changeRed" icon={iconError} size="l" mr={8} />
			</Text>
			<Text sx={{ flex: 1 }} color="typography-primary" size={size || 'small'}>
				{children}
			</Text>
		</Flex>
	);
}

export const FormError = forwardRef(FormErrorInner);
