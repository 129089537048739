import type { ComponentPropsWithRef, ForwardedRef, JSX } from 'react';
import { useMemo } from 'react';

import styled from '@emotion/styled';
import { Avatar as AvatarBase } from 'theme-ui';

import { forwardRef } from '@change-corgi/core/react/core';
import { Box } from '@change-corgi/design-system/layout';
import type { ResponsiveValue } from '@change-corgi/design-system/theme';
import { normalizeResponsiveValue } from '@change-corgi/design-system/theme';

export type Scale = 'xs' | 's' | 'm' | 'l' | 'xl';

export type AvatarVariant = 'default' | 'subtle' | 'ghost';

type Props = Omit<ComponentPropsWithRef<typeof AvatarBase>, 'size'> & {
	variant?: AvatarVariant;
	size?: ResponsiveValue<Scale | number>;
};

const VARIANT_DEFAULT_AVATAR: Record<AvatarVariant, string> = {
	default: 'https://static.change.org/profile-img/default-avatar-128.svg',
	subtle: 'https://static.change.org/profile-img/default-avatar-gray-128.svg',
	ghost: 'https://static.change.org/profile-img/default-avatar-128.svg', // not used by code
};

export const SCALE: Record<Scale, number> = {
	xs: 32,
	s: 40,
	m: 48,
	l: 56,
	xl: 88,
};

const StyledAvatar = styled(AvatarBase)`
	aspect-ratio: 1;
	object-fit: cover;
`;

function toSxSize(size: Scale | number | null | undefined) {
	return typeof size === 'string' ? SCALE[size] : size;
}

function AvatarInner(
	{ variant, size, src, alt, sx, ...rest }: Props,
	ref: ForwardedRef<HTMLImageElement>,
): JSX.Element {
	const sxSize = useMemo(() => {
		if (!Array.isArray(size)) return toSxSize(size);
		const sizeResponsive = normalizeResponsiveValue(size);
		return sizeResponsive.map(toSxSize);
	}, [size]);

	if (variant === 'ghost') {
		return (
			<Box
				sx={{
					borderRadius: 'full',
					backgroundColor: 'neutral-grey50',
					minWidth: sxSize,
					size: sxSize,
					// eslint-disable-next-line @typescript-eslint/no-misused-spread
					...sx, // only required for storybook to take overrides into account
				}}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				{...(rest as any)}
			/>
		);
	}

	return (
		<StyledAvatar
			sx={{
				minWidth: sxSize,
				size: sxSize,
				// eslint-disable-next-line @typescript-eslint/no-misused-spread
				...sx, // only required for storybook to take overrides into account
			}}
			src={src || VARIANT_DEFAULT_AVATAR[variant || 'default']}
			ref={ref}
			// explicit alt here so that it's not added to the ghost
			alt={alt}
			{...rest}
		/>
	);
}

/**
 * @doc $DOC:Avatar
 */
export const Avatar = forwardRef(AvatarInner);
