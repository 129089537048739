import type { ComponentPropsWithRef, ForwardedRef, JSX, PropsWithChildren } from 'react';
import { Children, useMemo } from 'react';

import type { ThemeUICSSObject } from 'theme-ui';

import { forwardRef } from '@change-corgi/core/react/core';
import type { MandatoryResponsiveValue } from '@change-corgi/design-system/theme';
import { normalizeResponsiveValue } from '@change-corgi/design-system/theme';

import { Box } from './Box';
import { Flex } from './Flex';

export type CardSpacing = 'xsmall' | 'small' | 'default';

type Props = Omit<ComponentPropsWithRef<typeof Flex>, 'variant' | 'color' | 'backgroundColor'> & {
	itemSpacing?: MandatoryResponsiveValue<CardSpacing>;
	itemsPerRow: MandatoryResponsiveValue<number>;
	itemWidth: MandatoryResponsiveValue<number | 'dynamic'>;
	maxListWidth?: ThemeUICSSObject['maxWidth'];
};

// Used for space between each item (gap), actual space between items is this value x2
const MARGIN_MAPPING: Record<CardSpacing, number> = {
	xsmall: 8,
	small: 16,
	default: 24,
};

function toContainerWidth(itemsPerRow: number, itemWidth: number | 'dynamic', margin: number) {
	if (itemWidth === 'dynamic') {
		return `calc(100% + ${itemsPerRow * margin * 2}px)`;
	}
	return itemsPerRow * itemWidth + itemsPerRow * margin * 2;
}

function CardListInner(
	{ children, itemsPerRow, itemWidth, maxListWidth, itemSpacing, sx, ...rest }: PropsWithChildren<Props>,
	ref: ForwardedRef<HTMLElement>,
): JSX.Element {
	const { mx, mb, containerMx, containerMb, width, containerWidth } = useMemo(() => {
		const itemsPerRowResponsive = normalizeResponsiveValue(itemsPerRow);
		const itemWidthResponsive = normalizeResponsiveValue(itemWidth);
		const margin = normalizeResponsiveValue(itemSpacing || 'default').map((s) => MARGIN_MAPPING[s]);

		return {
			width: itemWidthResponsive.map((w, i) =>
				w === 'dynamic' ? `calc((100% / ${itemsPerRowResponsive[i] || 1}) - ${margin[i] * 2}px)` : w,
			),
			mx: margin,
			mb: margin.map((m) => 2 * m),
			containerWidth: itemsPerRowResponsive.map((w, i) => toContainerWidth(w, itemWidthResponsive[i], margin[i])),
			containerMx: margin.map((m) => -m),
			containerMb: margin.map((m) => -2 * m),
		};
	}, [itemsPerRow, itemWidth, itemSpacing]);

	return (
		// using role due to https://www.scottohara.me/blog/2019/01/12/lists-and-safari.html
		<Flex
			sx={{
				alignItems: 'center',
				justifyContent: 'center',
				width: '100%',
				// eslint-disable-next-line @typescript-eslint/no-misused-spread
				...sx, // only required for storybook to take overrides into account
			}}
			{...rest}
			ref={ref}
		>
			<Flex sx={{ alignItems: 'center', justifyContent: 'center', width: '100%', maxWidth: maxListWidth }}>
				<Flex
					as="ul"
					role="list"
					p={0}
					mb={containerMb}
					mx={containerMx}
					sx={{ alignItems: 'stretch', flexWrap: 'wrap', width: containerWidth }}
				>
					{Children.map(children, (card) => (
						<Box as="li" mb={mb} mx={mx} sx={{ display: 'inline-block', width }}>
							{card}
						</Box>
					))}
				</Flex>
			</Flex>
		</Flex>
	);
}

/**
 * @doc $DOC:CardList
 */
export const CardList = forwardRef(CardListInner);
