import type { ComponentPropsWithRef, JSX } from 'react';
import { useMemo } from 'react';

import type { IconComponent } from '@change-corgi/design-system/icons';
import type { ResponsiveValue } from '@change-corgi/design-system/theme';
import { normalizeResponsiveValue } from '@change-corgi/design-system/theme';
import { Text } from '@change-corgi/design-system/typography';

export type Scale = 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl' | 'xxxl';

type Props = Omit<ComponentPropsWithRef<typeof Text>, 'size' | 'lineClamp' | 'ellipsis'> & {
	icon: IconComponent;
	size?: ResponsiveValue<Scale | number>;
};

const SCALE: Record<Scale, number> = {
	xxs: 10,
	xs: 12,
	s: 14,
	m: 16,
	l: 18,
	xl: 22,
	xxl: 28,
	xxxl: 48,
};

export const defaultSize: Scale = 'm';

function toFontSize(size: Scale | number | null | undefined) {
	return typeof size === 'string' ? SCALE[size] : size;
}

/**
 * @doc $DOC:Icon
 */
export function Icon({ size, icon: SvgIcon, sx, ...rest }: Props): JSX.Element {
	const fontSize = useMemo(() => {
		if (!Array.isArray(size)) return toFontSize(size);
		const sizeResponsive = normalizeResponsiveValue(size);
		return sizeResponsive.map(toFontSize);
	}, [size]);

	return (
		<Text
			sx={{
				display: 'inline-block',
				minWidth: '1em',
				minHeight: '1em',
				maxWidth: '1em',
				maxHeight: '1em',
				lineHeight: 1,
				fontSize,
				// eslint-disable-next-line @typescript-eslint/no-misused-spread
				...sx, // only required for storybook to take overrides into account
			}}
			{...rest}
		>
			<SvgIcon
				// needed as material icons don't have a fill attribute :/
				fill="currentColor"
			/>
		</Text>
	);
}
