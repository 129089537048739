import { keyframes } from '@emotion/react';
import type { ThemeUICSSObject } from 'theme-ui';

const animatePulse = keyframes`
  0% {
    opacity: 1;
  }
  60% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
`;

/**
 * @doc $DOC:pulse
 */
export function animatePulseStyles(durationMs = 2000): ThemeUICSSObject {
	return {
		// templated string necessary here for @emotion to generate the keyframes CSS
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-template-expression
		animationName: `${animatePulse}`,
		animationDuration: `${durationMs}ms`,
		animationIterationCount: 'infinite',
	};
}
