const inertSupported = (() => {
	let supported: boolean | undefined;
	return () => {
		if (supported !== undefined) {
			return supported;
		}
		const div = document.createElement('div');
		supported = 'inert' in div;
		return supported;
	};
})();

export function useRootAppInertToggle(rootAppSelector: string): {
	setInertOff: () => void;
	setInertOn: () => void;
} {
	const setInertOn = () => {
		const rootApp = document.querySelector(rootAppSelector);
		if (rootApp) {
			rootApp.setAttribute('inert', '');
			if (!inertSupported()) {
				// if inert is not supported, we need to hide the root app from screen readers using aria
				// we don't want to add the aria attribute if inert is supported as they would clash with each other (and result in a warning in the JS console)
				rootApp.setAttribute('aria-hidden', 'true');
			}
		}
	};
	const setInertOff = () => {
		const rootApp = document.querySelector(rootAppSelector);
		if (rootApp) {
			rootApp.removeAttribute('inert');
			if (!inertSupported()) {
				rootApp.removeAttribute('aria-hidden');
			}
		}
	};
	return { setInertOn, setInertOff };
}
