import { Children, cloneElement, isValidElement } from 'react';
import type { ComponentProps, ComponentPropsWithRef, ForwardedRef, JSX } from 'react';

import { forwardRef } from '@change-corgi/core/react/core';
import { Box } from '@change-corgi/design-system/layout';

import type { TabLink } from './TabLink';
import { TabLinkGroup } from './TabLinkGroup';
import type { TabLinksComponentElementType, TabLinksComponentName } from './types';

type Props = Omit<ComponentPropsWithRef<typeof Box>, 'children'> & {
	children: ReadonlyArray<React.ReactElement<ComponentProps<typeof TabLink>> | null | false | undefined>;
	selected: string;
};

function isTabComponent(type: TabLinksComponentElementType, name: TabLinksComponentName) {
	if (typeof type === 'string') return false;
	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
	return type.tabsComponentName === name;
}

function TabLinksInner({ children, selected, sx, ...rest }: Props, ref: ForwardedRef<HTMLDivElement>): JSX.Element {
	function renderChildren() {
		return Children.map(children, (child) => {
			if (!isValidElement<ComponentProps<typeof TabLink>>(child)) return null;
			if (isTabComponent(child.type as TabLinksComponentElementType, 'TabLink')) {
				// eslint-disable-next-line @typescript-eslint/naming-convention
				return cloneElement(child, { __internal_selected: selected === child.props.name });
			}

			return child;
		});
	}

	return (
		<Box
			sx={{
				width: '100%',
				// eslint-disable-next-line @typescript-eslint/no-misused-spread
				...sx, // only required for storybook to take overrides into account
			}}
			{...rest}
			ref={ref}
		>
			<TabLinkGroup>{renderChildren()}</TabLinkGroup>
		</Box>
	);
}

/**
 * @doc $DOC:TabLinks
 */
export const TabLinks = forwardRef(TabLinksInner);
