import type { ComponentPropsWithRef, ForwardedRef, JSX } from 'react';

import type { ThemeUICSSObject } from 'theme-ui';

import { forwardRef } from '@change-corgi/core/react/core';
import { useI18n } from '@change-corgi/core/react/i18n';
import { Box } from '@change-corgi/design-system/layout';

export type Variant = 'green' | 'purple' | 'blue';

export const variants: Variant[] = ['green', 'purple', 'blue'];

type Props = Omit<ComponentPropsWithRef<typeof Box>, 'variant'> & {
	ariaLabel?: string;
	completionPercentage: number;
	variant: Variant;
};

const variantStyles: Record<Variant, ThemeUICSSObject> = {
	green: {
		backgroundColor: 'secondary-green',
	},
	purple: {
		backgroundColor: 'secondary-purple',
	},
	blue: {
		backgroundColor: 'secondary-blue',
	},
};

function ProgressIndicatorInner(
	{ ariaLabel, completionPercentage, variant, sx, ...rest }: Props,
	ref: ForwardedRef<HTMLDivElement>,
): JSX.Element {
	const { translate } = useI18n();
	return (
		<Box
			role="progressbar"
			aria-label={ariaLabel || translate('design-system.progress-indicator.label')}
			aria-valuemin={0}
			aria-valuemax={100}
			aria-valuenow={Math.round(completionPercentage)}
			ref={ref}
			sx={{
				height: '8px',
				position: 'relative',
				// eslint-disable-next-line @typescript-eslint/no-misused-spread
				...sx, // only required for storybook to take overrides into account
			}}
			{...rest}
		>
			<Box
				sx={{
					borderRadius: '28px',
					width: '100%',
					maxWidth: '100%',
					height: '100%',
					position: 'absolute',
					top: 0,
					left: 0,
					overflow: 'hidden',
				}}
			>
				<Box
					sx={{
						borderRadius: '28px',
						// using a 100px bigger bar so that we conserve a radius
						width: `calc(100px + ${Math.round(completionPercentage)}%)`,
						transform: 'translateX(-100px)',
						height: '100%',
						...variantStyles[variant],
					}}
				/>
			</Box>
			<Box
				sx={{
					borderStyle: 'solid',
					borderWidth: '1px',
					borderRadius: '28px',
					width: '100%',
					height: '100%',
					borderColor: 'neutral-grey600',
					position: 'absolute',
					top: 0,
					left: 0,
				}}
			/>
		</Box>
	);
}

/**
 * @doc $DOC:ProgressIndicator
 */
export const ProgressIndicator = forwardRef(ProgressIndicatorInner);
